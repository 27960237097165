import {
  Button,
  Modal,
  Box,
  TextField,
  Alert,
  LinearProgress
 } from '@mui/material';
import React, { useState, useEffect } from "react";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Editor = (props) => {

  const [status, setStatus] = useState("loading")
  const [activity, setActivity] = useState()
  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")

  useEffect(() => {
    setActivity(props.selected_activity)
    setStatus("200")
  }, [props.selected_activity])

  const saveActivity = () => {
    if (!checkSafeToSave()) {
      setAlertCode("fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      var body = {
        id: activity._id,
        activity: activity,
      }
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      fetch(API + "/activity/set/", 
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(body),
            }
      )
      .then(response => response.json())
      .then(result => {
        setAlertCode(result.status === "ok" ? "ok" : "fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)
      })
    }
 
  }

  const checkSafeToSave = () => {
    let safe = true
    safe = safe && activity["_id"].length > 0
    safe = safe && !activity["_id"].includes("_")
    safe = safe && !activity["_id"].includes(":")
    safe = safe && !activity["_id"].includes(" ")
    safe = safe && !activity["_id"].includes(";")
    safe = safe && activity["name"].length > 0
    safe = safe && !activity["name"].includes(":")
    return safe
  }

  return (
    <Modal
        open={props.open}
        onClose={() => {props.close(false)}}
      >
        <Box className='activity_editor_modal_container'>
          <Box className="activity_editor_title">
            <h2>Definición</h2>
          </Box>

          <Box className="activity_editor_buttons_row">
            <Button variant="contained" color="secondary" onClick={() => {saveActivity()}}>Guardar</Button>
          </Box>
          
          {
            status === "loading"
            ? 
              <Box sx={{ width: '60%', marginLeft: "20%", marginTop: 5 }}>
                <LinearProgress color="secondary" />
              </Box> 
            : <></>
          }

          { 
            status === "200" 
            ?
              
              <Box className="activity_editor_form">
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="name"
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      value={activity.name}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.name = event.target.value
                        setActivity(new_activity)
                      }}/>
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="_id"
                      label="Código de contenidos"
                      variant="outlined"
                      fullWidth
                      value={activity["_id"]}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity["_id"] = event.target.value
                        setActivity(new_activity)
                      }}/>
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="web"
                      label="URL web"
                      variant="outlined"
                      fullWidth
                      value={activity.web}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.web = event.target.value
                        setActivity(new_activity)
                      }}/>
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="web"
                      label="URL Google Docs"
                      variant="outlined"
                      fullWidth
                      value={activity.drive}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.drive = event.target.value
                        setActivity(new_activity)
                      }}/>
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="web"
                      label="Código de actividad gemela"
                      variant="outlined"
                      fullWidth
                      value={activity.twin}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.twin = event.target.value
                        setActivity(new_activity)
                      }}
                    />
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="coverage"
                      label="Porcentaje de cobertura"
                      variant="outlined"
                      fullWidth
                      value={activity.coverage}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.coverage = event.target.value
                        setActivity(new_activity)
                      }}
                    />
                  </Box>
                </Box>
                <Box className="activity_editor_form_row">
                  <Box className="activity_editor_form_input">
                    <TextField
                      id="notes"
                      label="Notas"
                      variant="outlined"
                      multiline
                      minRows={5}
                      fullWidth
                      value={activity.notes}
                      onChange={event => {
                        let new_activity = {...activity}
                        new_activity.notes = event.target.value
                        setActivity(new_activity)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            : <></>
          }

          { show_alert ? 
            <Box className="alert_container">
              <Alert severity={alert_code === "ok" ? "success" : "error"}>
                {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
              </Alert>
            </Box>
          : <></> }

        </Box>        
    </Modal>
  )
}

export default Editor