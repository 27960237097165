import { 
    Box,
    Modal,
} from '@mui/material';
import React from "react";
import ReactJson from 'react-json-view'

const Viewer = (props) => {

    return (
        <Modal
            open={props.value !== null}
            onClose={() => {props.setViewerValue(null)}}
        >
            { props.value !== null ?
            <Box className="history_viewer_modal_container">
                <Box className="history_viewer_modal_title">
                    <h2>Comparador</h2>
                </Box>
                <Box className="history_viewer_modal_comparator">
                    <Box className="history_viewer_modal_column">
                        <h3>Antiguo</h3>
                        <ReactJson src={props.value.old} />
                    </Box>
                    <Box className="history_viewer_modal_column">
                        <h3>Nuevo</h3>
                        <ReactJson src={props.value.new} />
                    </Box>
                </Box>
            </Box>   
            : <></> }
        </Modal>
    )
}

export default Viewer