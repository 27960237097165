import {
  Box,
  Chip
} from '@mui/material';
import React, { useEffect, useState } from "react";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import "./EngineAlert.css"

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const EngineAlert = (props) => {

  const [engine, setEngine] = useState(true)

  useEffect(() => {
    // Get engine status every second
    const interval = setInterval(() => {
      fetch(API + "/engine/?id=" + props.engine_id, 
          {
            method: 'GET',
            headers: AuthHeader()
          }
      )
      .then((response) => response.json())
      .then((data) => {
        setEngine(data)
      })
    },1000)
    return () => clearInterval(interval)
  }, [props.engine_id])

  const changeEngine = () => {
    let engine_id = props.engine_id !== "engine_1" ? "engine_1" : "engine_" + props.user.username
    props.setEngineId(engine_id)
    var date = new Date();
    date.setTime(date.getTime() + (365*24*60*60*1000));
    document.cookie = "engine_id=" + engine_id + "; expires=" + date.toUTCString();
  }

  return (
    <Box className="engine_alert_container">
        <Chip
          icon={((Date.now()/1000) - engine.heartbeat) < 5 ? <CloudQueueIcon/> : <CloudOffIcon/> }
          label={engine.name}
          variant="outlined"
          onClick={() => {
            changeEngine()
          }}
        />
    </Box>
  )
}

export default EngineAlert