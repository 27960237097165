import { Box } from '@mui/material';
import React, { useEffect } from "react";
import List from "../components/users/List"

import "./Users.css"

const Users = (props) => {

  useEffect(() => {props.setScrumbs("Usuaries")}, [props])
  
  return (
    <Box className="users_page">
      <List/>
    </Box>
  )
}

export default Users