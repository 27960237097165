import { Stack, IconButton, Tooltip } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


const GlobalActions = (props) => {
    return (
        <Stack direction="row" spacing={1}>
            <Tooltip title="Ejecutar test en bulk">
                <IconButton onClick={() => {
                    props.setOpenScrapDialog(true)
                }}> 
                <CloudDownloadIcon style={{color: "#7C3785"}}/>
                </IconButton>
            </Tooltip>  
        </Stack>
    );
}

export default GlobalActions;