import React from "react";
import {
    Modal,
    Box,
   } from '@mui/material';

const LogModal = (props) => {

    return (
        <Modal
            open={props.open}
            onClose={() => {props.close(false)}}
        >
            <Box className='test_log_modal_container'>
                <Box className="test_log_modal_title">
                    <h2>Log de ejecución</h2>
                </Box>

                <Box className="test_log_modal_text">
                    {props.log}
                </Box>

            </Box>        
        </Modal>
    )

}

export default LogModal