import {
    Button,
    Dialog,
    DialogTitle,
    Box
   } from '@mui/material';
  
import React from "react";

const Execution = (props) => {

    return (
        <Dialog onClose={() => {props.close(false)}} open={props.opend} className="scrapping_dialog">
            <DialogTitle>Ejecución en bulk</DialogTitle>
            <Box className='execution_buttons_container'>
                <Box className='execution_button'>
                    <Button disabled variant="contained" color="secondary" classes="execution_button" onClick={() => {props.batchScrap("android"); props.close(false)}}>Ejecutar Android</Button>
                </Box>
                <Box className='execution_button'>
                    <Button variant="contained" color="secondary" classes="execution_button" onClick={() => {props.batchScrap("web"); props.close(false)}}>Ejecutar web</Button>
                </Box>
                <Box className='execution_button'>
                    <Button variant="contained" color="secondary" classes="execution_button" onClick={() => {props.batchScrap("all"); props.close(false)}}>Ejecutar todas</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
  
 export default Execution