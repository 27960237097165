import { 
    Box,
    TextField,
    Alert,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    IconButton
   } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SaveIcon from '@mui/icons-material/Save';
import React, { useState, useEffect } from "react"; 
import { useParams } from 'react-router-dom'; 

import FourOOne from '../components/general/FourOOne';

import "./User.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const User = (props) => {

    const { username } = useParams()
    const [status, setStatus] = useState("loading")
    const [user, setUser] = useState()
    const [show_alert, setShowAlert] = useState(false)
    const [alert_code, setAlertCode] = useState("")
    const [role, setRole] = useState("tester")

    useEffect(() => {props.setScrumbs("Mi perfil")})

    useEffect(() => {
        let username_addon = username === "yo" ? "" : "?username=" + username
        fetch(API + "/user/" + username_addon, 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => {            
            if (response.status === 200) {
                return response.json()
            } else if (response.status === 401) {
                setStatus("401")                
                return null
            }
        })
        .then((data) => {
            if (data) {
                setUser(data)
                setStatus("200")
            }
        })
    }, [username])

    useEffect(() => {
        fetch(API + "/user/", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            setRole(data["role"])
        })
    }, [])

    const save = () => {
        var body = {
            username: user.username,
            user: user
        }
        var headers = AuthHeader()
        headers.append("Content-Type", "application/json");
        fetch(API + "/user/set/", 
                {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(body),
                }
        )
        .then(response => response.json())
        .then(result => {
            setAlertCode(result.status === "ok" ? "ok" : "fail")
            setShowAlert(true)
            setTimeout(() => {
              setShowAlert(false)
            }, 2000)
        })
    }

    const logout = () => {
      document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.location.href = "/login"
    }

    return (
        <Box className="user_page">
            { status === "loading" 
            ?
                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <LinearProgress color="secondary" />
                </Box> 
            : <></> }
            { status === "401" 
            ?
                <FourOOne/>
            : <></> }
            { status === "200" 
            ?
                <>   
                    <Box className='actions_row'>
                        <Box className="actions_row_left_container">              
                
                        </Box>
                        <Box className="actions_row_right_container">
                                <Tooltip title="Guardar cambios"> 
                                    <IconButton onClick={() => {
                                        save()
                                    }}>
                                        <SaveIcon/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Cerrar sesión"> 
                                    <IconButton onClick={() => {
                                        logout()
                                    }}>
                                        <LogoutIcon/>
                                    </IconButton>
                                </Tooltip>
                        </Box>
                        </Box>
                    <Box className="user_container">
                        <Box className="user_section_title"><h2>Hola {user.name}</h2></Box>
                        <Box className="user_form">
                            <Box className="user_form_row">
                                <Box className="user_form_input">
                                    <TextField
                                        id="username"
                                        label="Nombre de usuarie"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        value={user.username}
                                        disabled
                                    />
                                </Box>
                            </Box>
                            <Box className="user_form_row">
                                <Box className="user_form_input">
                                    <TextField
                                        id="name"
                                        label="Nombre"
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        value={user.name}
                                        onChange={event => {
                                            let new_user = {...user}
                                            new_user.name = event.target.value
                                            setUser(new_user)
                                        }} 
                                    />
                                </Box>
                            </Box>
                            <Box className="user_form_row">
                                <Box className="user_form_input">
                                    <FormControl fullWidth>
                                        <InputLabel id="campaign" color="secondary">Role</InputLabel>
                                        <Select
                                            labelId = "role"
                                            id = "role"
                                            variant="outlined"
                                            color="secondary"
                                            value = {user.role}
                                            label = "role"
                                            disabled={role !== "admin"}
                                            onChange = {event => {
                                                let new_user = {...user}
                                                new_user.role = event.target.value
                                                setUser(new_user)
                                            }}
                                        >
                                            <MenuItem value="admin">Admin</MenuItem>
                                            <MenuItem value="viewer">Viewer</MenuItem>
                                            <MenuItem value="tester">Tester</MenuItem>
                                        </Select>
                                    </FormControl>   
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    { show_alert ? 
                        <Box className="alert_container">
                        <Alert severity={alert_code === "ok" ? "success" : "error"}>
                            {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
                        </Alert>
                        </Box>
                    : <></> }
                </>
            : <></> }
        </Box>
    )
}

export default User