import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Switch,
    Button,
} from '@mui/material';
import React, {useState, useEffect} from "react";

import Move from './Move'
  
import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Steps = (props) => {

    const [images, setImages] = useState({loading: true})

    useEffect(() => {
        fetch(API + "/image/list/?id=" + props.test["_id"] + "&step=all&length=1", 
                {
                method: 'GET',
                headers: AuthHeader()
                }
        )
        .then((response) => response.json())
        .then((data) => {
            setImages(data)
        })
        .catch((error) => console.log(error))
    }, [props.test])

    const addStep = (event, position) => {
        var new_step = {}
        if (event === "go") {
            new_step = {
                "event": "go",
                "url": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "click") {
            new_step = {
                "event": "click",
                "expresion": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "type") {
            new_step = {
                "event": "type",
                "expresion": "",
                "text": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "scroll") {
            new_step = {
                "event": "scroll",
                "y": "end",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "file") {
            new_step = {
                "event": "file",
                "expresion": "",
                "file": "cam.mp4",
                "wait-to-load": 1,
                "extract": false
            }
        }  else if (event === "system_variable") {
            new_step = {
                "event": "system_variable",
                "expresion": "",
                "variable_name": "",
                "wait-to-load": 1,
                "extract": false
            }
        }  

        let new_test = {...props.test}
        if (position === 0)
            new_test.navigate.push(new_step)

        props.setTest(new_test)
    }

    const moveStep = (old_index, new_index) => {
        let new_test = {...props.test}
        new_test.navigate.splice(new_index, 0, new_test.navigate.splice(old_index, 1)[0]);
        props.setTest(new_test)
    }

    const deleteStep = (index) => {
        let new_test = {...props.test}
        new_test.navigate.splice(index, 1);
        props.setTest(new_test)
    }

    return (
        <Box className="test_editor_form"> 
                {
                    props.test.navigate.map((step, index) => {
                        return (
                            <Box className="test_editor_form_step" key={index}>
                                <Box className="test_editor_form_step_row">
                                    <Box className="test_editor_form_title">
                                    <h3>Paso {index} - {step.event}</h3>
                                    </Box>
                                    <Move index={index} steps={props.test.navigate.length} move={moveStep} delete={deleteStep}/>
                                </Box>

                                {
                                    step.event === "go" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="url"
                                            label="URL"
                                            variant="outlined"
                                            fullWidth
                                            value={step.url}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].url = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        </Box>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>
                                    </>
                                    : <></>
                                }

                                {
                                    step.event === "click" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                                            <TextField
                                            id="expresion"
                                            label="Expresion"
                                            variant="outlined"
                                            fullWidth
                                            value={step.expresion}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].expresion = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        </Box>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>
                                    </>
                                    : <></>
                                }

                                {
                                    step.event === "type" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                                            <TextField
                                            id="expresion"
                                            label="Expresion"
                                            variant="outlined"
                                            fullWidth
                                            value={step.expresion}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].expresion = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        </Box>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="text"
                                            label="Text"
                                            variant="outlined"
                                            fullWidth
                                            value={step["text"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].text = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>                              
                                    </>
                                    : <></>
                                }

                                {
                                    step.event === "scroll" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="y"
                                            label="Y"
                                            variant="outlined"
                                            fullWidth
                                            value={step.y}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                if (event.target.value === "end") {
                                                new_test.navigate[index].y = event.target.value
                                                } else {
                                                new_test.navigate[index].y = parseInt(event.target.value)
                                                }
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>                          
                                    </>
                                    : <></>
                                }

                                {
                                    step.event === "file" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                                            <TextField
                                            id="expresion"
                                            label="Expresion"
                                            variant="outlined"
                                            fullWidth
                                            value={step.expresion}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].expresion = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        </Box>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={step.file}
                                            label="type"
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].file = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            >
                                            <MenuItem value="cam.mp4">Video</MenuItem>
                                            <MenuItem value="playful_R2D2.mp3">Sonido</MenuItem>
                                            <MenuItem value="puzzle.jpg">Imagen</MenuItem>
                                            </Select>
                                        </FormControl>  
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>                       
                                    </>
                                    : <></>
                                }

                                {
                                    step.event === "system_variable" ? 
                                    <>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                                            <TextField
                                            id="expresion"
                                            label="Expresion"
                                            variant="outlined"
                                            fullWidth
                                            value={step.expresion}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].expresion = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        </Box>
                                        <Box className="test_editor_form_step_row">
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="variable_name"
                                            label="Nombre de variable"
                                            variant="outlined"
                                            fullWidth
                                            value={step.variable_name}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index].variable_name = event.target.value
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <TextField
                                            id="wait-to-load"
                                            label="Espera para cargar"
                                            variant="outlined"
                                            fullWidth
                                            value={step["wait-to-load"]}
                                            onChange={event => {
                                                let new_test = {...props.test}
                                                new_test.navigate[index]["wait-to-load"] = parseInt(event.target.value)
                                                props.setTest(new_test)
                                            }}
                                            />
                                        </Box>
                                        <Box className="test_editor_form_input">
                                            <FormControlLabel
                                            control={
                                                <Switch
                                                checked={step.extract}
                                                onChange={event => {
                                                    let new_test = {...props.test}
                                                    new_test.navigate[index].extract = event.target.checked
                                                    props.setTest(new_test)
                                                }}
                                                />
                                            }
                                            label="Capturar"
                                            />
                                        </Box>              
                                        </Box>                              
                                    </>
                                    : <></>
                                }
                                {
                                    step.extract && props.test["type"] === "web" && Object.keys(images).includes(index.toString())
                                    ?
                                        <img src={API + "/image/?id=" + images[index.toString()][0] + "&no-cache-string=" + Math.random()} alt="Captura de pantalla del paso"/>
                                    :
                                        <></>
                                    
                                }

                            </Box>

                            
                        )
                        }
                    )
                }
                <Box className="test_editor_add_step">
                    <h3>Añadir paso</h3>
                    <Box className="test_editor_add_step_buttons_row">
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}} onClick={() => {addStep("go",0)}}>Ir a URL</Button>
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}} onClick={() => {addStep("click",0)}}>Clicar</Button>
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}} onClick={() => {addStep("type",0)}}>Escribir</Button>
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}} onClick={() => {addStep("scroll",0)}}>Scroll</Button>
                        <Button variant="outlined" color="secondary" style={{marginRight: 10}} onClick={() => {addStep("file",0)}}>Archivo</Button>
                        <Button variant="outlined" color="secondary" onClick={() => {addStep("system_variable",0)}}>Variable de sistema</Button>
                    </Box>
                </Box>
        
        </Box>
    )
}

export default Steps