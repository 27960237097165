import { Box, Button,  Card, CardActions, CardContent, Typography, LinearProgress } from '@mui/material';
import React, {useState, useEffect} from "react";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Procesess = (props) => {

    const [processes, setProcesses] = useState({loading: true});

    useEffect(() => {
        fetch(API + "/processes/", 
            {
            method: 'GET',
            headers: AuthHeader()
            }
        )
        .then((response) => response.json())
        .then((data) => {
            let _itinerary_code = ""
            if (props.activity_id.includes("I")) {
                _itinerary_code = props.activity_id.split("A")[0].split("I")[1]
            } else {
                _itinerary_code = props.activity_id.split("A")[0]
            }
            let _activity_code = props.activity_id.split("A")[1] 
            let _processes = []
            for (let i = 0; i < data.length; i++) {
                let process = data[i]
                if (process["It"].toString() === _itinerary_code && process["Nº"].toString() === _activity_code) {
                    if (process["Estado"] !== "Resolved") {
                        process["spreadsheet_index"] = i
                        _processes.push(process)
                    }
                }
            }
            setProcesses(_processes)
        })
        .catch((error) => console.log(error))
    }, [props.activity_id])       

  return (
    <>
    {
        processes.loading
        ?
            <Box sx={{ width: '80%', marginLeft: "10%", marginTop: 5 }}>
                <LinearProgress color="secondary" />
            </Box> 
        :
            <Box className="process_container">
                {
                    processes.length === 0
                    ?
                        <Box className="processes_clear_cotainer">
                            <h2> No hay reportes abiertos </h2>
                        </Box>
                    :
                        <Box className="cards_container">
                            {
                                processes.map((process, index) => {
                                    var bg_color = "white"
                                    if (process["Estado"] === "Pending approval") bg_color = "#FBD999"
                                    else if (process["Estado"] === "Discarded") bg_color = "#B4E3DF"
                                    else if (process["Estado"] === "Internal revision") bg_color = "#DD99C0"
                                    else if (process["Estado"] === "To Do") bg_color = "#C5A6C9"
                                    else if (process["Estado"] === "In progress") bg_color = "#DD99C0"

                                    // var text = process["Comentarios cambios Apps"]

                                    return (
                                        <Card sx={{backgroundColor: bg_color }} key={index}>
                                            <CardContent>
                                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {process["Fecha entrada"]}
                                                </Typography>
                                                <Typography variant="h5" component="div">
                                                    {process["Estado"]}
                                                </Typography>
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    {process["Urgencia"]} - {process["Motivo"]}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {process["Comentarios cambios Apps"]}
                                                </Typography>
                                                <br/>
                                                <Typography variant="body2">
                                                    {process["Comentarios mejoras"]}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" onClick={() => {window.open("https://docs.google.com/spreadsheets/d/1zCzVb6UaU8Atf-t1zSKCedEJdsmkpilPy8DHiOYoQLo/edit#gid=1260086339&range=A" + (process.spreadsheet_index + 2),'_blank');}}>Ver en el listado</Button>
                                            </CardActions>
                                        </Card>
                                    )
                                })
                            }
                        </Box>
                    }
                
            </Box>
        }
    </>
  )
}

export default Procesess