import { 
  Box,
  Button,
  LinearProgress,
  IconButton,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  Alert
} from '@mui/material';
import React, { useState, useEffect } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const ActivitiesButtons = (props) => {

  const [activities, setActivities] = useState({loading: true})
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [show_alert, setShowAlert] = useState(false)

  const handleMenuItemClick = (event, index) => {
    props.setActivityToReview(activities[index])
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  
  useEffect(() => {
    fetch(API + "/activities/?test_id=" + props.test_id, 
        {
        method: 'GET',
        headers: AuthHeader()
        }
    )
    .then((response) => response.json())
    .then((data) => {
      setActivities(data)
      props.setActivityToReview(data[0])

    })
    .catch((error) => console.log(error))
  }, [props.test_id])

  return (
    <>
      {
        activities.loading
        ?
            <Box sx={{ width: '100%', marginTop: 5 }}>
                <LinearProgress color="secondary" />
            </Box> 
        :
          <Box className="activities_buttons_container">
            <Box className="activities_buttons_list">
              <ButtonGroup 
                color="secondary"
                variant="text"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => {setOpen((prevOpen) => !prevOpen)}}
                >
                  {activities[selectedIndex]._id} - {activities[selectedIndex].name}
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu" autoFocusItem>
                          {activities.map((activity, index) => (
                            <MenuItem
                              key={index}
                              selected={index === selectedIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {activity._id} - {activity.name}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
            <Box>
              <Tooltip title="Copiar url de la web">
                <IconButton onClick={() => {
                    navigator.clipboard.writeText(activities[selectedIndex].web);
                    setShowAlert(true)
                    setTimeout(() => setShowAlert(false), 5000)
                }}>
                    <ContentCopyIcon style={{ width: 20, marginLeft: 5 }}/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Abrir en otra pestaña">
                <IconButton onClick={() => {
                  window.open(activities[selectedIndex].web, "_blanck").focus()
                }}>
                    <OpenInNewIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
      }
      { show_alert ? 
          <Box className="alert_container">
            <Alert severity="info">
                Copiado al portapapeles
            </Alert>
          </Box>
      : <></> }
    </>
  )
}

export default ActivitiesButtons