import { Chip, Stack, Tooltip, Divider } from '@mui/material';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import React, { useState, useEffect } from "react";

const Filters = (props) => {

    const [filters_count, setFiltersCount] = useState({"all": 0, "fail": 0, "alerts": 0,})

    useEffect(() => {
        var _filters_count = {"all": 0, "fail": 0, "alerts": 0,}
        _filters_count.all = props.tests.length
        var status_ready = Object.keys(props.tests_status).length > 0
        for (var i = 0; i < props.tests.length; i++) {
            if (props.tests[i].alerts > 0)
                _filters_count.alerts += 1

            if (status_ready) {
                if (props.tests_status[props.tests[i]["_id"]].status === "fail")
                    _filters_count.fail += 1
            } 
        }
        setFiltersCount(_filters_count)

    }, [props.tests, props.tests_status])

    const addToQuery = (filter) => {
        let query = props.search_query
        if (!query.includes(filter))
            query += " " + filter
        else
            query = query.replace(" " + filter, "")
        props.setSearchQuery(query)
    }

    const enabled = (filter) => {
        if (props.search_query.includes(filter))
            return "#E2D2E4"
        else
            return "white"
    }

    return (
        <Stack direction="row" spacing={1}>
            <Tooltip title="Todos los test">
                <Chip 
                    icon={<GroupWorkIcon />}
                    label={filters_count.all}
                    variant="outlined"
                    sx={{backgroundColor: props.search_query === "" ? "#E2D2E4" : "white"}}
                    onClick={() => props.setSearchQuery("")}
                />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Test Web">
                <Chip
                    label={"Web"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/type:web/")}}
                    onClick={() => addToQuery("/type:web/")}
                />
            </Tooltip>
            <Tooltip title="Test Android">
                <Chip
                   label={"Android"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/type:android/")}}
                    onClick={() => addToQuery("/type:android/")}
                />
            </Tooltip>
            <Tooltip title="Test iOS">
                <Chip
                    label={"iOS"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/type:ios/")}}
                    onClick={() => addToQuery("/type:ios/")}
                />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Tests manuales">
                <Chip
                    label={"Manual"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/engine:manual/")}}
                    onClick={() => addToQuery("/engine:manual/")}
                />
            </Tooltip>
            <Tooltip title="Tests con interacción de usuarie">
                <Chip
                    label={"Interacción"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/user_interaction:true/")}}
                    onClick={() => addToQuery("/user_interaction:true/")}
                />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Test con error">
                <Chip
                    label={"Fallos: " + filters_count.fail}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/status:fail/")}}
                    onClick={() => addToQuery("/status:fail/")}
                />
            </Tooltip>
            <Tooltip title="Test sin error">
                <Chip
                    label={"Pasa"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/status:ok/")}}
                    onClick={() => addToQuery("/status:ok/")}
                />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Test con alertas">
                <Chip
                    label={"Alertas: " + filters_count.alerts}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/alerts>0/")}}
                    onClick={() => addToQuery("/alerts>0/")}
                />
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Test que se ejecutan diariamente">
                <Chip 
                    label={"Daily"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/campaign:daily/")}}
                    onClick={() => addToQuery("/campaign:daily/")}
                />
            </Tooltip>
            <Tooltip title="Test en camapaña de formaciones">
                <Chip
                    label={"Formaciones"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/campaign:training/")}}
                    onClick={() => addToQuery("/campaign:training/")}
                />
            </Tooltip>
            <Tooltip title="Test en campaña de FVE">
                <Chip 
                    label={"FVE"}
                    variant="outlined"
                    sx={{backgroundColor: enabled("/campaign:fve/")}}
                    onClick={() => addToQuery("/campaign:fve/")}
                />
            </Tooltip>
        </Stack>
    );
}

export default Filters;